// import logo from './logo.svg';
import './App.css';
import {  BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from './components/Home'
import EBU from './components/EBU'
import Check from './components/Check';
import {AuthProvider} from './contexts/AuthContext'
import { getAnalytics, logEvent } from "firebase/analytics";
import ScrollToTop from './components/ScrollToTop'
const analytics = getAnalytics();
logEvent(analytics, 'notification_received');

function App() {
  return (
   <>
     <BrowserRouter>
     <AuthProvider>
       <ScrollToTop>
        <Routes>
        <Route path="/" element={<Homepage/>} exact/>
        <Route path="/ebu" element={<EBU/>}/>
        <Route path="/rdr" element={<Check/>}/>
        </Routes>
        </ScrollToTop>   
        </AuthProvider>
        </BrowserRouter>
    
   </>
  );
}

export default App;
