import React, {useState, /*useEffect*/} from 'react'
import { useNavigate } from "react-router-dom"
import {Link} from "react-router-dom";
import AI from "../assets/AI.png"
import EbuAmbassador from "../assets/EBU Ambassador Scholarship.png"
import LogoLinkedIn from '../assets/linkedin-logo.png'
// import {useAuth} from '../contexts/AuthContext'
import { getDatabase, ref, update} from 'firebase/database';
import initializeAuthentication from '../utils/firebase.init';
import { useAuth } from '../contexts/AuthContext'
import { getAnalytics, logEvent } from "firebase/analytics";

initializeAuthentication();
const db = getDatabase();
const analytics = getAnalytics();
logEvent(analytics, 'notification_received');

let getResult = ""

export default function Home() {
  const navigate = useNavigate()
  // const [dbData, setDbData] = useState()
  const [,setGetRandomInt] = useState()
  const {setCurrentUser } = useAuth()
  const [registerUser, setRegisterUser] = useState({ 
    fname: "",
    lname: "",
    email: "",
  })



  function Database(){
    // const refrence = ref(db, "User/" + registerUser.fname + " " + registerUser.lname + " " + getRandomInt + "/"   );
    console.log("This is me " + getResult)
    const refrence = ref(db, "User/" + getResult + "/"   );

    if (registerUser.fname !== "" && registerUser.lname !== "" && registerUser.email !== "" ) {
      
      update(refrence,{
        fname: registerUser.fname,
        lname: registerUser.lname,
        email: registerUser.email,
      })

      setCurrentUser(true)
  
      navigate("/rdr")
    } else {
      console.log("Failed to submit. Input empty")
    }


      // onValue(refrence, (snapshot) => {
      //     // setDbData(snapshot.val());

      //     if (snapshot.exists()) {
      //       console.log(snapshot.val());
      //     } else {
            
      //     }
        
      //     })
      // console.log("Hello")
  }

//   function handleEmail(e) {
//     e.preventDefault()
//     let a = e.target.name
//     setGetRandomInt(Math.floor(Math.random() * 78453))

//     setRegisterUser({
//       fname: a === "fname" ? e.target.value : registerUser.fname,
//       lname: a === "lname" ? e.target.value : registerUser.lname,
//       email: a === "email" ? e.target.value : registerUser.email,
//     })

// }

  function RegisterEmail(e) {
    e.preventDefault()
    // console.log(registerUser.fname)
    // console.log(registerUser.lname)
    // console.log(registerUser.email)
    // console.log(getRandomInt)
    BuildUser(registerUser.email)
    // console.log(getRandomInt)
    // Database()
    // navigate("/ebu")
    // console.log(registerUser)
    // console.log(registerEmail)
  }

  function BuildUser(email){
   
    let isSupplied = email.split("")

    isSupplied.forEach( (ele)=>{
   
    if (ele === "@") {
        // console.log(getResult)
        Database()
        return
    } else {
        getResult = getResult + ele
        // console.log(getResult)
    }
    // setRegisterUser({email:getResult})
})
// console.log(getResult)
    
}

function handlePage (){
  navigate("/ebu")
}
  return (
    <>
    {/* <div className='text text-[25px]'> */}

        <div className="bg-black pb-[1rem]">
      <header className="">
        <h1 className='pt-12 text-5xl font-black text-center mb-12 pop text-white'>Limited Offer</h1>
      </header>
        {/* <p className='text-cente px- mx-3 text-2xl font-thi source text-indigo- text-center'>Secure a lifetime opportunity to study at a world class renowed university at 97% off  </p> */}
        <p className='m-3 mx-3 text-xl font-thin source text-center text-white'>Get certified by enrolling and save up to €718</p>

    </div>
      <div className='my-8'>
        <h2 className='text-4xl text-cente m-3 font-medium'>Study at
        European Business University (EBU)</h2>
        {/* <p className='p-3'>Get a lifetime opportunity to get a recognized certificate at a discounted price
         by choosing diffrent courses to begin with. 
         From Business Mangement to Introduction to AI
        </p> */}
        <p className='p-3'>
        Affordable Education for All is here to guide you on the step by step process of getting quality and affordable education at
        <Link to="https://ebulux.lu/" target='_blank' className='text-green-500'> <b>European Business University (EBU )</b></Link> an international accredited university in Luxembourg.
        </p>

        <p className='p-3'>
        As your <Link to="https://www.linkedin.com/in/chinelo-adaeze/" target='_blank'> <b className='text-green-500'>EBU Scholarship Ambassador</b></Link>, I’m here to show you how. <br/><br/> EBU has over 120 partners as sponsors, and this has made it possible for everyone to get a scholarship opportunity in one of its over 150 Certificate Impact Program.
        </p>

        <div className='p-2'>
          <h3 className='font-semibold text-center text-3xl my-4'>Limited Slots Available</h3>
          <img className='rounded-lg my-8 mx-auto' src={AI} alt="EBU Introduction To AI"/>
          <p className='p-3'>
          It’s Certificate Impact Program Courses are valued at €740 per course, but with a promotion code from your EBU Scholarship Ambassador you get to pay a Non - Refundable €22.5 Commitment Fee.  
          </p>
          <p className='p-3'>
          Next term is starting in May 2023, and EBU is offering Artificial Intelligence for the first time, making it possible for anyone curious about the role of Artificial Intelligence, and its administration to enroll in CDA 300; Introduction to Artificial Intelligence and gain insights.
          </p>
          <p className='p-3'>
          Why not take advantage of this great opportunity now, as limited Scholarships are available at a Non - Refundable Commitment Fee of €22.5. Classes have maximum numbers that it can take and they fill up quickly.
          </p>
          

        </div>

        <div className=''>
          <h3 className='text-center font-bold text-3xl'>How to Secure a Slot</h3>
          <img className='rounded-lg my-8 mx-auto ebuIMG' src={EbuAmbassador} alt="EBU Ambassador Scholarship Program"/>
          <p className='p-2'>
          To apply for an EBU Certificate Impact Program Scholarship Award and receive your EBU 
          Scholarship Promotion Code, contact your EBU Scholarship Ambassador by filling out this form 
          and you’ll get the step by step process of enrolling and getting started. 
          </p>
        </div>
        
        {/* <form className='mx-auto block my-3 mx-3 mb-6 bg-gray-300 p-6 rounded-lg'>
          <p className='text-center text-2xl'>Show Intrest By Filling The Form</p>
          <input onChange={handleEmail} placeholder='First Name' className='block mx-auto py-3 px-24 pl-2 rounded outline-none bg-zinc-100 my-5' type="text" name="fname" required />
          <input onChange={handleEmail} placeholder='Surname' className='block mx-auto py-3 px-24 pl-2 rounded outline-none bg-zinc-100 my-5' type="text" name="lname" required />
          <input onChange={handleEmail} placeholder='Email' className='block mx-auto py-3 px-24 pl-2 rounded outline-none bg-zinc-100 my-5' type="email" name="email" required />
        <button onClick={RegisterEmail} className='px-6 py-3 bg-black text-white rounded-lg my-6 mx-auto block' type='submit'>Learn How</button>
        </form> */}

        {/* <button className='px-6 py-3 bg-black text-white rounded-lg my-6 mx-auto block' onClick={RegisterEmail}>Check Email's</button> */}

      </div>
      <div onClick={handlePage} className="font-semibold text-center text-2xl    px-6 py-3 bg-black text-white rounded-lg my-6 mx-auto block w-80 underline">Learn How To Enroll</div>

      <footer className='m bg-black font-thin p-4 text-center'>
        <Link to="https://www.linkedin.com/in/chinelo-adaeze/" target='_blank' className=''>
        <img className=' bg-blue-600 rounded-full p-[0.1rem] w-[2.4rem] block mx-auto mb-2' src= {LogoLinkedIn} alt='Linkedin Logo'/>
        </Link>
        <p className='text-white text-[15px]'>All rights reserved &copy; 2023</p>
      </footer>
   
    </>
  )
}
