import React, { useContext, useState } from "react"
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, onValue } from 'firebase/database';
import initializeAuthentication from '../utils/firebase.init';
// import {app} from '../utils/firebase.config'
// import second from '../utils/firebase.config'
// import { useNavigate } from "react-router-dom"

initializeAuthentication();
// const provider = new GoogleAuthProvider();
const AuthContext = React.createContext();
// const auth = getAuth();
const db = getDatabase();

export function useAuth() {
    return (
      useContext(AuthContext)
    )
  }

  
  export function AuthProvider({ children }) {
    // const [loading, setLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState(false)
    const [dbData, setDbData] = useState()

    function Database() {
      const refrence = ref(db, "email" /*+ currentUser.uid*/ );
      // const uIdDbRef = ref(db, "users/" + currentUser.uid +"/" + "KYC")
      // let counter = 1

      onValue(refrence, (snapshot) => {
          setDbData (snapshot.val());

          if (snapshot.exists()) {
            console.log(snapshot.val());
          } else {
            
          }
        
          })
      console.log("Hello")

   
  }
  // setCurrentUser(5)
    
    const value = {
        currentUser,
        dbData,
        Database,
        setCurrentUser,
    }
    return (
        <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    )
  }
  