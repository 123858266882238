import React from 'react'
import {Link} from "react-router-dom";
import LogoLinkedIn from '../assets/linkedin-logo.png'

export default function EBU() {
  return (
    <>
        <div className="bg-black pb-[1rem]">
      <header className="">
        <h1 className='pt-12 text-5xl font-black text-center mb-12 text-white'>Enrollment Steps Into European Business University of Luxembourg</h1>
      </header>
        {/* <p className='text-cente px- mx-3 text-2xl font-thi'>Secure a lifetime opportunity to study at a world class renowed university at 97% off  </p>
        <p className='m-3 mx-3 text-xl font-thin'>Get certified by enrolling now and save up to €718</p>

        <form className='mx-auto block'>
          <label className=''>Email</label>
          <input placeholder='Email' className='block mx-auto py-3 px-24 pl-2 rounded outline-none bg-zinc-300' type="email" />
        </form>

        <button className='px-6 py-3 bg-black text-white rounded-lg my-6 mx-auto block'>Learn How</button> */}
    </div>
        
        <div className='p-2 my-4 text-[20px] font-semibold text-cente'>
            <h2 className=''>Follow the outlined steps to enroll into a program of your choice:</h2>
        </div>

        <div className='mx-4'>
            <ul className='list-disc m-3 leading-6' type="bullet">
                <li className='py-7'>Go to  <Link className='text-green-500' to="https://connect.ebulux.lu/" target="_blank"> EBU Connect</Link></li>
                <li className='py-7'>Click "create new account" </li>
                <li className='py-3'>Find your EBU Scholarship Ambassador’s name <b className='pop'>"Chinelo Adaeze Nwankwo"</b> and select it as your ambassador</li>
                <li className='py-7'>Go back to the homepage by directly clicking "home" on the left corner.</li>
                <li className='py-7'>Click the course you like on the "home" page where you see all courses on the slide</li>
                <li className='py-7'>Select the course and press enter.</li>
                <li className='py-7'>Fill in the form, click submit, and proceed to pay your commitment fee of €22.50</li>
                <li className='py-7'>Enter the coupon code: <b className='capitalize'>TVN7VNNR</b> and click Apply code. (copy and paste the code without any spaces at the beginning or end)</li>
                <li className='py-7 list-none font-semibold text-red-500'><b>Note:</b> Use coupon code <b className='text-black pop capitalize'>TVN7VNNR</b>. <br/><br/>The scholarship admission team will enroll you in your course and confirm by sending you an email.</li>
                <li className='py-7'>Enter card details to complete the commitment fee payment. Once you complete your commitment fee payment, you will be enrolled in your course and ready to commence learning in May 2023.</li>
                <li className='py-7'>Make sure to read the course description before enrolling so you are well-informed on the expected course content and prerequisites if any.</li>
                <li className='py-7'>If you have any challenges please leave a comment and I'll get back to you as soon as possible. 
                <br/><br/><b className='pop'>Don't wait, slots fill up very fast. Register, pay now, and begin your study in May 2023.</b></li>
                <li className='py-7'>Lastly, when classes begin, endeavour to attend because that is the only way you 
                can learn and make a difference in your feild.</li>
            </ul>

            <div className='my-3 mx-3 mb-6 bg-gray-300 p-6 rounded-lg'>
                <h2 className=''>Quote to remember</h2>
    
                <blockquote className='py-3'>“If a man empties his purse into his head, no one can take it from him. An investment in knowledge always pays the highest return.” Benjamin Franklin</blockquote>

            </div>

        </div>
        <footer className='m bg-black font-thin p-4 text-center'>
        <Link to="https://www.linkedin.com/in/chinelo-adaeze/" target='_blank' className=''>
        <img className=' bg-blue-600 rounded-full p-[0.1rem] w-[2.4rem] block mx-auto mb-2' src= {LogoLinkedIn} alt='Linkedin Logo'/>
        </Link>
        <p className='text-white text-[15px]'>All rights reserved &copy; 2023</p>
      </footer>
    </>
  )
}
