import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom"
import { useAuth } from '../contexts/AuthContext'
import {Link} from "react-router-dom";
import { getDatabase, ref, onValue} from 'firebase/database';
let getResult = ""
const db = getDatabase();

export default function Check() {
  const navigate = useNavigate()
  const [ timer, setTimer ] = useState(6)
  const [ userIsVerified, setUserIsVerified ] = useState(false)
  const { currentUser, setCurrentUser } = useAuth()
  const [ getEmail, setGetEmail ] = useState()
  const [ error, setError ] = useState(false)

  console.log(currentUser)

  function Counter() {
    // if (timer === 0) {
      
    // }
    if (timer !== 0 && currentUser) {
      setTimer( timer - 1)
      
    }else{
      console.log(currentUser, userIsVerified)
      // navigate("/ebu")
     }
    
  }
  
  console.log(timer)
  useEffect( ()=> {
    const myTimer = setTimeout(Counter, 1000)
    if (timer === 0) {
      navigate("/ebu")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[timer, currentUser])

  function Database(){
    // const refrence = ref(db, "User/" /* + registerUser.fname + " " + registerUser.lname + " " + getRandomInt + "/" */   );
    const refrence = ref(db, "User/"  + getResult + "/"  );

    onValue(refrence, (snapshot) => {
          // setDbData(snapshot.val());

          if (snapshot.exists()) {
            if (snapshot.val().email === getEmail) {
              console.log(getEmail);
              setUserIsVerified(true)
              setCurrentUser(true)
            }
          } else {
            console.log("User dosen't exist")
            setError(true)
            // DisplayError()
          }
        
          })
  }

  function BuildUser(email){
   
    let isSupplied = email.split("")
    getResult = ""

    isSupplied.forEach( (ele)=>{
   
    if (ele === "@") {
        // console.log(getResult)
        Database()
        return
    } else {
        getResult = getResult + ele
        // console.log(getResult)
    }
    // setRegisterUser({email:getResult})
})
// console.log(getResult)
    
}

function handleForm(e) {
  e.preventDefault()
  let email = e.target.value
  setGetEmail(email)
}

function CheckDB(e) {
  e.preventDefault()
  BuildUser(getEmail)
  // console.log(getEmail)
}

function UserState() {
 if (userIsVerified) {
  return (
    <div className='centered w-[20rem]'>
          <p className='text-2xl font-bold text-center'>{` Welcome Back  `}</p>
          <p className=''>{`Redirecting you in `} {timer}</p>
          <p className=''></p>
      </div>
  )
 } else {
   return (
    <div className='centered w-[20rem]'>
    <p className=''>We have recieved your details and you will be redirected to learn more in {timer}</p>
    <p className=''></p>
</div>
   )
 }
}

function DisplayError() {
  return (
    <p className='text-red-500 font-bold text-center mb-4'>User does not exist</p>
  )
}

  
  return (
    <>
    {
      currentUser ? 
      //   (
          
      //     <div className='centered w-[20rem]'>
      //     <p className=''>We have recieved your details and you will be redirected to learn more in {timer}</p>
      //     <p className=''></p>
      // </div>
      //   ) 

      UserState()
       
      : (
        <div className='centered w-[25rem]'>
          <form className='mx-auto block my-3 mx-3 mb-6 bg-gray-300 p-6 rounded-lg'>
          <p className='text-center text-2xl'>Enter your registered email</p>
          {/* <label className=''>Email</label> */}
          <input onChange={handleForm} placeholder='Email' className='block mx-auto py-3 px-24 pl-2 rounded outline-none bg-zinc-100 my-5' type="email" name="email" required />
          
        <button onClick={CheckDB} className='px-6 py-3 bg-black text-white rounded-lg my-6 mx-auto block' type='submit'>Sign In</button>
        {error ? DisplayError() : ""}
        <Link to="/" className='text-blue-800 font-bold underline'>Create Account?</Link>
        </form>
        </div>
      )
    }
       
    </>
  )
}
